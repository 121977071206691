import React from 'react';

const Home = () => {
  const [currentTime, setCurrentTime] = React.useState(null);
  const [error, setError] = React.useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  React.useEffect(() => {
    fetch(`${API_URL}/time`)
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to fetch data');
        }
        return res.json();
      })
      .then(data => {
        setCurrentTime(data.time);
      })
      .catch(error => {
        setError(error.message);
      });
  }, [API_URL]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Home</h1>
      {currentTime !== null ? (
        <p>The current time is {currentTime}.</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Home;
