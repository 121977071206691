import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ setAuth }) => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${API_URL}/profile`, {
      method: 'GET',
      credentials: 'include'
    })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        setProfile(data);
      } else {
        setError(data.message);
        setAuth(false);
        navigate('/login');
      }
    })
    .catch(err => setError('Failed to fetch profile information.'));
  }, [API_URL, setAuth, navigate]);

  const handleLogout = () => {
    fetch(`${API_URL}/logout`, {
      method: 'POST',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setAuth(false);
        navigate('/login');
      } else {
        setError('Logout failed. Please try again.');
      }
    });
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Dashboard</h1>
      {profile ? (
        <div>
          <p>Name: {profile.name}</p>
          <p>Username: {profile.username}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Dashboard;
